import ItemList from "../ItemList/ItemList";

const NuevosIngresos = () => {

    return (
        <div>
            <ItemList nuevosIngresos={true} />
        </div>
    )
}
export default NuevosIngresos;